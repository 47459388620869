body {
  color: var(--text-color);
  font-size: 11pt;
  font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.actions {
  padding-top: 20px;
  padding-bottom: 5px;
  text-align: right;
  width: 100%;
}

.omv-avatar {
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid var(--header-text-color);
}

.omv-centered {
  color: var(--text-color);
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}

.omv-dialog {
  background-color: var(--dialog-background-color);
  border-radius: 5px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.omv-overlay {
  color: var(--primary-accent-color);
}

.omv-study-indicator {
  color: var(--text-color);
  font-size: 24pt;
  position: relative;
  top: 10px;
}

.omv-pagebody {
  overflow: 'auto';
  padding: 10px;
  text-align: left;
}

.omv-pageheader {
  background-color: var(--title-bar-color);
  display: flex;
  height: 52px;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 14px;
  text-align: left;
}

.omv-pageheader-warning {
  background-color: #550000;
  display: flex;
  height: 52px;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 14px;
  text-align: left;
}

.omv-pageheader h3,
.omv-pageheader-warning h3 {
  color: var(--title-bar-text-color);
}

.omv-pageheader Button,
.omv-pageheader-warning Button {
  margin-right: 10px;
  top: -7px;
}

.omv-pageroot {

  background-color: var(--body-color);
  overflow: hidden;
  width: 100%;
  height: 100%;

}

.omv-splitpanel-left {
  background-color: var(--left-panel-color);
  overflow: auto;
}

.omv-splitpanel-right {
  background-color: var(--body-color);
  overflow: auto;
}

/** VERY top header **/

.omv-header {
  background-color: var(--header-color);
  color: var(--header-text-color);
  height: 60px;
  padding: 10px;
  vertical-align: middle;
  width: 100%;
}

.omv-header>.title {

  color: var(--header-text-color);
  display: flex;
  font-size: 14pt;
  padding: 7px 10px;

}

.omv-header>.toolbar {

  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;

}

.error,
.colorError,
.pink,
.omv-secondary-accent {
  color: var(--secondary-accent-color);
}

.colorSilver,
.silver,
.omv-primary-border {
  color: var(--primary-border-color);
}

.primary-accent,
.success,
.colorBlue,
.colorSuccess,
.blue,
.omv-primary-accent {
  color: var(--primary-accent-color);
}

textarea {
  background-color: var(--body-color);
  border: 1px solid var(--primary-border-color);
  border-radius: 4px;
  color: var(--text-color);
  padding: 5px;
}

/** font size **/

h2 {
  color: var(--primary-accent-color);
  font-size: 16pt;
  font-weight: bold;
}

h3 {
  color: var(--primary-accent-color);
  font-size: 13pt;
  font-weight: bold;
}

h4 {
  color: var(--primary-accent-color);
  font-size: 12pt;
  font-weight: bold;
}

.font12 {
  font-size: 12pt;
  padding-bottom: 5px;
}

.bold {
  font-weight: bold;
}

/** basic alignments and padding **/

.leftAlign {
  text-align: left;
}

.centerAlign {
  text-align: center;
}

.rightAlign {
  text-align: right;
}

.flex {
  display: flex;
}

.link {
  text-decoration: underline;
  color: var(--text-color);
  cursor: pointer;
}

.neg5 {
  position: relative;
  top: -5px;
}

.neg10 {
  position: relative;
  top: -10px;
}

.neg15 {
  position: relative;
  top: -12px;
}

.pad10 {
  padding: 10px;
}

.pad10left,
.pad10l {
  padding-left: 20px;
}

.pad20r {
  padding-right: 20px;
}

.pad10vertical,
.pad10v {
  padding: 10px 0px;
}

.pad15 {
  padding: 15px;
}

.pointer {
  cursor: pointer;
}

.space-between,
.spaceBetween {
  justify-content: space-between;
}

/** borders **/

.border-2 {
  border-width: 2px;
  border-style: solid;
}

.border-1 {
  border-width: 1px;
  border-style: solid;
}

.highlighted-border {
  border-color: var(--primary-accent-color);
}

.primary-border {
  border-color: var(--primary-border-color);
}

.secondary-border {
  border-color: var(--secondary-border-color);
}

.border-right,
.borderRight {
  border-right: 1px solid var(--primary-border-color);
}

/** dialog and form formatting **/

.formRow {
  display: flex;
  padding: 5px 0px;
  text-align: left;
}

.formRow>div {
  display: inline;
}

.formRow>label.short {
  color: var(--text-color);
  width: 150px;
  margin-right: 20px;
}

.formRow>label {
  color: var(--text-color);
  width: 200px;
  margin-right: 20px;
}

.formRow>label.long {
  color: var(--text-color);
  width: 300px;
  margin-right: 20px;
}

.session-notes-panel {
  background-color: var(--header-color);
  border-radius: 5px;
  bottom: 10px;
  padding: 10px;
  position: absolute;
  right: 10px;
  width: 450px;
  z-index: 50;

}

/* OTHER */

.noselect {

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */

}

/* splitter */

.splitter-bar {
  position: relative;
  background-color: black;
  top: 0px;
  width: 11px;
  cursor: col-resize;
  transition: background-color 0.25s ease;
  height: 100%;
}

.splitter-bar-horz {
  position: relative;
  background-color: black;
  left: 0px;
  height: 11px;
  cursor: row-resize;
  transition: background-color 0.25s ease;
  width: 100%;
}

.splitter-bar-collapsed {
  position: relative;
  top: 0px;
  width: 25px;
  cursor: col-resize;
  transition: background-color 0.25s ease;
  height: 100%;
  background-color: black;
  cursor: pointer;
}

.splitter-bar-collapsed-horz {
  position: relative;
  left: 0px;
  height: 25px;
  cursor: row-resize;
  transition: background-color 0.25s ease;
  width: 100%;
  background-color: black;
  cursor: pointer;
}

.splitter-bar:hover,
.splitter-bar-horz:hover {
  background-color: var(--primary-accent-color);
}

.splitter-bar>.splitter-bar-toggle-button {
  display: none;
}

.splitter-bar-horz>.splitter-bar-toggle-button-horz {
  display: none;
}

/*** when splitter bar is collased, button is positioned in the middle **/

.splitter-bar-collapsed>.splitter-bar-toggle-button {
  position: relative;
  left: 7px;
  top: calc(50% - 15px);
  background-color: inherit;
  border: none;
}

.splitter-bar-collapsed-horz>.splitter-bar-toggle-button-horz {
  position: relative;
  top: -15px;
  left: calc(50% - 15px);
  background-color: inherit;
  border: none;
}

/*** end splitter bar collapse positioning ***/

.splitter-bar-toggle-button {
  background-color: #333333;
  border: 1px solid silver;
  width: 9px;
  height: 30px;
  cursor: pointer;
  padding: 0px;
  z-index: 300;
}

.splitter-bar-toggle-button-horz {
  background-color: #333333;
  border: 1px solid silver;
  width: 30px;
  height: 9px;
  cursor: pointer;
  padding: 0px;
  z-index: 300;
}

/***** the little arrow chevrons ****/

.little-arrow {
  color: var(--text-color)
}

.splitter-bar-toggle-button>.little-arrow {
  margin-left: -7px;
  margin-top: -9px;
}

.splitter-bar-toggle-button-horz>.little-arrow {
  margin-left: 3px;
  margin-top: -7px;
}

/**** end chevrons *****/

.move-handle {
  position: absolute;
  width: 11px;
  background-color: #555555;
  z-index: 999;
  cursor: col-resize;
}

.move-handle-horz {
  position: absolute;
  width: 100%;
  height: 11px;
  background-color: #555555;
  z-index: 9999;
  cursor: row-resize;
}

/** datagrid **/

.crmdatagridRoot {
  overflow-y: auto;
  overflow-x: hidden;
}

th,
.list-header {
  background-color: var(--list-header-color);
  color: var(--text-color);
  text-align: left;
  padding-left: 5px;
  height: 52px;
  white-space: nowrap;
}

th:first-child {
  padding-left: 20px;
}

td {
  padding-left: 5px;
  text-align: left;
  white-space: nowrap;
  border-bottom: 1px solid var(--secondary-border-color);
  color: var(--text-color);
  overflow: hidden;
  text-overflow: ellipsis;
}

td:first-child {
  padding-left: 20px;
}

tr {
  line-height: 30px;
}

tr:hover {
  background-color: var(--list-hover-color);
  cursor: pointer;
}

tr.selected,
tr.selected td {
  background-color: var(--list-selection-color);
  color: var(--list-selection-text-color);
}

/*** filter box ****/

/** page buttons ***/

.pageButton {
  display: inline-block;
  padding: 5px;
  border: 1px solid var(--primary-border-color);
  color: var(--text-color);
  cursor: pointer;
  width: 42px;

}

.pageButtonCurrent {
  display: inline-block;
  padding: 5px;
  border: 1px solid var(--primary-border-color);
  color: var(--body-color);
  background-color: var(--primary-border-color);
  width: 42px;
}

/** autcomplete styles **/

.option {
  padding: 5px 10px;
  font-size: 11pt;
}

.pathistory-option {
  display: grid;
  font-size: 11pt;
  grid-template-columns: [first] 235px [second] 20px;

}

.pathistory-option:hover .deleteIcon {
  visibility: visible;
}

.deleteIcon {
  color: var(--text-color);
  cursor: pointer;
  margin-top: 10px;
  visibility: hidden;
}

.historyClearAll {
  color: #f290b1;
  font-size: '11pt';
  font-weight: 'bold';
}

/*
.historyPatientName {
  font-weight: bold;
} */

.historyPatientID {
  color: var(--primary-border-color);
}


.historyPatientTime {
  color: var(--primary-border-color);
}


.acsmall {
  background-color: white;
  height: '30px';
  width: '250px';
}

/** layout designer related **/

/* layout designer related */

.viewportbox {
  display: inline-block;
  height: 100px;
  width: 100px;
  border: solid 1px var(--primary-border-color);
  margin: 5px 5px;
  font-size: 10pt;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}

.viewportbox-selected {
  display: inline-block;
  height: 100px;
  width: 100px;
  border: solid 2px var(--primary-accent-color);
  margin: 5px 5px;
  font-size: 10pt;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}

.imageIndex {
  font-size: 10pt;
}

.seriesIndex {
  font-size: 22pt;
  padding-top: 2px;
  max-width: 95px;
  max-height: 50px;
  overflow: hidden;
}

.seriesName {
  font-size: 14pt;
  padding-top: 10px;
  padding-bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.studyIndex {
  font-size: 10pt;
  padding-top: 5px;
}

/** responsiveness **/

@media screen and (-webkit-min-device-pixel-ratio:0) {

  select,
  textarea,
  input {
    font-size: 16px;
  }
}

/** super small - phone size **/
@media only screen and (min-width:1px) and (max-width:767px) {
  /* was 768px */

  .sm,
  .sm-md,
  .sm-md-lg {
    display: "";
  }

  .md,
  .md-lg,
  .lg {
    display: none;
  }

  .dialog-md,
  .dialog-lg {
    height: 100%;
    width: 100%;
  }

  .hCentered {

    margin: 0px auto;
    text-align: left;
    width: 100%;

  }


}

/** medium - tablet size **/
@media only screen and (min-width:768px) and (max-width:1050px) {
  /* was 768px */

  .sm,
  .lg {
    display: none;
  }

  .sm-md,
  .sm-md-lg,
  .md,
  .md-lg {
    display: "";
  }

  .dialog-md {
    width: 500px;
  }

  .dialog-lg {
    width: 650px;
  }

  .hCentered {

    margin: 0px auto;
    text-align: left;
    width: 80%;

  }

}

/** large - pc screens **/
@media only screen and (min-width: 1051px) {
  /* was 768px */

  .sm,
  .sm-md,
  .md {
    display: none;
  }

  .sm-md-lg,
  .md-lg,
  .lg {
    display: "";
  }

  .dialog-md {
    width: 600px;
  }

  .dialog-lg {
    width: 850px;
  }

  .hCentered {

    margin: 0px auto;
    text-align: left;
    width: 60%;
  }

}

/** for the calendar picker icon in MUI */

::-webkit-calendar-picker-indicator {
  filter: invert(98%) sepia(8%) saturate(81%) hue-rotate(310deg) brightness(113%) contrast(100%);
}

/** snackbar **/

.bg-primary-accent {
  background-color: var(--primary-accent-color);
}

.bg-secondary-accent {
  background-color: var(--secondary-accent-color);
}

.link-black {
  cursor: pointer;
  color: black;
  text-decoration: underline;
}

/*** side panels **/

.list-header {
  background-color: var(--list-header-color);
}

.panel-header {

  background-color: #424242;
  border-bottom: 1px solid #515151;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  overflow: hidden;
  padding: 5px 10px 5px 21px;
  text-align: left;
  text-overflow: clip;
  white-space: nowrap;

}

/** image viewport navigation bar **/

.onb-ft22 {
  font-size: 22pt;
}

.onb-fps-label {
  display: inline-block;
  padding: 0px 5px;
  position: relative;
  top: -12px;
}

.onb-root,
.onb-root-static {
  background-color: #333333;
  bottom: 0px;
  color: white;
  display: flex;
  justify-content: space-between;
  height: 35px;
  left: 0px;
  opacity: 0;
  position: absolute;
  text-align: center;
  transition: all 0.25s linear;
  width: 100%;
}

.onb-root:hover,
.onb-root-static {
  opacity: 1;
}

.onb-root>div,
.onb-root-static>div,
.onb-root:hover>div {
  padding: 5px 5px;
}

.onb-left {
  margin-top: -7px;
  text-align: left;
  width: 250px;
}

.onb-middle {
  text-align: center;
  padding: 0px;
}

.onb-right {
  text-align: right;
  width: 250px;
  padding-right: 5px;
}

.annotation-toolbar {

  background-color: var(--header-color);
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 50;

}
